import React, { useState } from "react";
import InputField from "../components/commons/InputField";
import ButtonsNavigation from '../components/commons/ButtonsNavigation'
import {Formik} from 'formik'
import {formValidation} from '../validations/login.js'
import { apiClient } from "../api/client";
import { useNavigate } from "react-router-dom";

function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const form = {
    username: '',
    password: ''
  }

  const LoginForm = ({ handleSubmit }) => {
    return (
      <>
        { loading
          ? <div className='spn-container'>
              <span className='spinner'></span>
            </div>
          : <form style={{display: 'flex', flexDirection: 'column', padding: '10px 0'}} onSubmit={handleSubmit}>
              <div style={{display: 'flex', flexDirection: 'column', gap: 4, marginBottom: 30}}>
                <span>Usuario:</span>
                <InputField type='text' name='username' />
              </div>
              <div style={{display: 'flex', flexDirection: 'column', gap: 4}}>
                <span>Contraseña:</span>
                <InputField type='password' name='password' />
              </div>
              <p style={{color: 'red', margin: 0, marginTop: 10}}>{error}</p>
              <ButtonsNavigation
                onClick={() => true}
              />
            </form>
        }
      </>
    )
  }

  const onSubmit = async (values) => {
    setError('')
    setLoading(true)
    try {
      const { accessToken, refreshToken } = await apiClient.post('/admin/login', values)
      localStorage.setItem('accessToken', accessToken)
      localStorage.setItem('refreshToken', refreshToken)
      localStorage.setItem('username', values.username)
      navigate('/admin/listado')
      setLoading(false)
    } catch (error) {
      setError('Ha ocurrido un error, inténtelo de nuevo.')
      setLoading(false)
    }
  }

  return (
    <Formik
      component={LoginForm}
      initialValues={form}
      onSubmit={onSubmit}
      validationSchema={formValidation}
    >
    </Formik>
  )
}

export default Login