import * as yup from 'yup'

export const formValidation = yup.object().shape({
  personsAffected: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required('Debes escribir tu nombre'),
      lastName: yup
        .string()
        .required('Debes escribir tu apellido'),
      job: yup
        .string()
        .required('Campo obligatorio'),
      relation: yup
        .string()
        .required('Campo obligatorio'),
    })
  )
})