import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiClient } from "../api/client";
import '../styles/pages/AdminPortal.css'
import { transformedDate } from '../common/methods'
import ComplaintsListItem from "../components/ComplaintsListItem";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

function AdminPortal() {
  let fromDate = new Date()
  fromDate = fromDate.setDate(fromDate.getDate() - 30)

  const [complaintsList, setComplaitsList] = useState([])
  const [stats, setStats] = useState({})
  const [loading, setLoading] = useState(false)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)
  const [startDate, setStartDate] = useState(transformedDate(fromDate, 'toApi'))
  const [endDate, setEndDate] = useState(transformedDate(new Date(), 'toApi'))

  const navigate = useNavigate()

  useEffect(() => {
    const updateWidth = () => {
      setInnerWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    let wrapper = document.getElementById('wrapper')
    if (innerWidth > 768) {
      wrapper?.classList.remove('wrapper')
    } else {
      wrapper?.classList.add('wrapper')
    }

    return () => window.removeEventListener('resize', updateWidth)
  }, [innerWidth])
  
  useEffect(() => {
    setLoading(true)

    if (!localStorage.getItem('accessToken')) {
      navigate('/admin/login')
    }

    let from = transformedDate(fromDate, 'toApi')
    let to = transformedDate(Date.now(), 'toApi')

    if (startDate.length > 0) {
      from = startDate
    }

    if (endDate.length > 0) {
      to = endDate
    }

    const getComplaintList = async() => {
      const { complaints, stats } = await apiClient.get(`/admin/complaint?from=${from}&to=${to}`)
      setComplaitsList(complaints.reverse())
      setStats(stats)
      setLoading(false)
    }
    
    getComplaintList()
  }, [startDate, endDate])

  return (
    <>
      { loading
          ? <div className='spn-container'>
              <span className='spinner'></span>
            </div>
          : <>
              <h2 className="complaint-title">Consulta por fecha</h2>
              <div style={{ border: '1px solid #00372A' }}>
                <div className="date">
                  <div className="wrapper-date">
                    <label>Fecha inicio</label>
                    <DatePicker 
                      style={{padding: 4, border: '1px solid #00372A'}} 
                      dateFormat="dd/MM/yyyy" 
                      selected={new Date(startDate)} 
                      onChange={(date) => setStartDate(transformedDate(date.getTime(), 'toApi'))} 
                    />
                  </div>
                  <div className="wrapper-date">
                    <label>Fecha fin</label>
                    <DatePicker 
                      style={{padding: 4, border: '1px solid #00372A'}} 
                      dateFormat="dd/MM/yyyy" 
                      selected={new Date(endDate)} 
                      onChange={(date) =>  setEndDate(transformedDate(date.getTime(), 'toApi'))} 
                    />
                  </div>
                </div>
              </div>
              <div style={{ marginBottom: 40 }}>
                <h4 style={{fontSize: 16, marginBottom: 20}}>Comunicaciones recibidas en este período:</h4>
                <div className="complaint-stats-grid">
                  {Object.keys(stats)?.map((stat, i) => (
                    <div key={i} className="complaint-stats-item">
                      <strong style={{ fontSize: '12px' }}>{stats[stat].label}:</strong>   
                      <p>{stats[stat].count}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="complaint-title">Comunicaciones Recibidas</div>
              {complaintsList.length === 0 
                ? <p style={{textAlign: 'center', padding: 20}}>No se han encontrado comunicaciones.</p>
                : <div className="container">
                    <div className="complaints-list-wrapper complaints-list-desktop-header">
                      <div className="complaint column-ref">
                        <p style={{fontWeight: 600}}>Número de referencia</p>
                      </div>
                      <div className="complaint column-status">
                        <p style={{fontWeight: 600}}>Estado</p>
                      </div>
                      <div className="complaint column-date">
                        <p style={{fontWeight: 600}}>Fecha</p>
                      </div>

                      <div className="complaint column-actions"></div>
                    </div>
                    {complaintsList?.map(complaint => <ComplaintsListItem key={complaint.id} complaint={complaint} />)}
                  </div>
              }
            </>
      }  
    </>
  )
}

export default AdminPortal
