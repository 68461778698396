import React, {useEffect, useState} from 'react'
import {Formik} from 'formik'
import InputField from '../commons/InputField'
import ButtonsNavigation from '../commons/ButtonsNavigation'
import {useNavigate, useOutletContext} from 'react-router-dom'
import { apiClient } from '../../api/client'
import {Button} from '@mui/material';
import '../../styles/steps/StepIndex.css'

function Step8() {
  const [step, setStep, form, setForm] = useOutletContext()
  const [loading, setLoading] = useState(false)
  const [complaintSent, setComplaintSent] = useState(false)
  const [referenceLabel, setReferenceLabelLabel] = useState('')
  const [error, setError] = useState('')

  const navigate = useNavigate()

  const handleDataConsentCheck = () => {
    setForm(prevForm => ({
      ...prevForm,
      dataConsentCheck: !form.dataConsentCheck
    }))
  }

  const sendComplaint = async () => {
    setError('')
    setLoading(true)
    try {
      const { text, complainant, attachments, personsAffected } = form
      const sendToApi = {
        text,
        complainant,
        personsAffected,
        attachments
      }

      const response = await apiClient.post('/complaint', sendToApi)
      setLoading(false)
      setComplaintSent(true)
      setReferenceLabelLabel(response.reference)
    } catch (error) {
      if (error?.errors.hasOwnProperty('text') && error.errors.text) {
        setError('Por favor, compruebe que no hayan campos vacíos.')
      } else {
        setError('Lo siento, ha ocurrido un error, intenténtelo de nuevo.')
      }

      setLoading(false)
    }
  }

  const Form = ({ handleSubmit, isSubmitting, status }) => {
    return (
      <>
        {loading && 
          <div className='spn-container'>
            <span className='spinner'></span>
            <p style={{color: 'black'}}>Procesando...</p>
          </div>
        }
        {(!loading && !complaintSent) && <form onSubmit={handleSubmit}>
            <div style={{color: 'black', margin: 0, marginTop: 30, padding: '0 10px'}}>
              <p style={{ margin: '0 0 20px 10px'}}><strong>Advertencia</strong>: está a punto de enviar una comunicación.</p>
              <label style={{ display: 'flex', alignItems: 'flex-start' }}>
                <InputField type='checkbox' name='dataConsentCheck' onChange={handleDataConsentCheck} checked={form.dataConsentCheck} />
                <p style={{ margin: 0, marginTop: 8, cursor: 'pointer'}}>Tengo motivos razonables y fundamentados para considerar que la comunicación que voy a enviar es veraz, y he sido informado de que se podrán adoptar medidas legales y/o disciplinarias, e informar a las Fuerzas y Cuerpos de Seguridad del Estado sobre el uso indebido del canal de denuncias, en caso de que utilice este canal con mala fe, de forma contraria a la finalidad que tiene prevista en la Ley o movido por intereses subjetivos, inmorales o ilegítimos.
                </p>
              </label>
                <p style={{ margin: 0, margin: 0, marginTop: 20, color: 'red', textAlign: 'center'}}>{error}</p>
            </div>
            <ButtonsNavigation
              onBackClick={() => navigate('/paso/' + (step - 1))}
              onClick={sendComplaint}
              isLastStep={true}
              consentChecked={form.dataConsentCheck}
            />
          </form>
        }
        {(!loading && complaintSent) &&
          <div 
            style={{
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              color: 'black', 
              marginBottom: 50, 
              marginTop: 50
              }}
            >
            <span style={{textAlign: 'center', marginTop: 30, fontSize: 24, marginBottom: 20}}>La comunicación se ha enviado</span>
            { referenceLabel.length > 0 && <p>Número de referencia: <strong>{referenceLabel}</strong></p>}
            <Button
              type="button"
              variant="contained"
              style={{
                backgroundColor: '#00372A',
                fontWeight: 'bold',
                borderRadius: 0,
                marginTop: 30
              }}
              onClick={() => navigate('/')}
            >
              <span>Volver al inicio</span>
            </Button>
          </div>
        }
      </>
    )
  }

  useEffect(() => {
    setStep(8)
    if (!form.termsAndConditions || !form.termsOfUse) navigate('/')
  }, [])

  return (
    <Formik
      component={Form}
    >
    </Formik>
  )
}

export default Step8
