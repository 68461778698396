import React, { useEffect, useState } from 'react'
import {apiClient} from '../../api/client'
import {Checkbox} from '@mui/material'
import {useNavigate, useOutletContext} from 'react-router-dom';
import ButtonsNavigation from '../commons/ButtonsNavigation';
import { checkboxClasses } from "@mui/material/Checkbox";
import '../../styles/steps/StepIndex.css'

function Step1() {
  const [isLoading, setLoading] = useState(true)
  const [text, setText] = useState({__html: ''})
  const [isDirty, setDirty] = useState(false)

  const [step, setStep, form, setForm, innerWidth] = useOutletContext()
  const navigate = useNavigate()

  const fetchText = async () => {
    const response = await apiClient.get(process.env.REACT_APP_ENDPOINT_TEXT_STEP1)
    setText({__html: response})
    setLoading(false)
  }

  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  const nextPage = () => {
    setDirty(true)
    if (form.termsAndConditions) {
      navigate('/paso/' + (step + 1))
    }
  }

  const openModal = () => {
    document.querySelector('div.continueModal')?.classList.add('visible')
  }

  const handleChange = ({ target }) => {
    setDirty(true)
    setForm(prevForm => ({
      ...prevForm,
      termsAndConditions: target.checked
    }))
  }

  useEffect(() => {
    setStep(1)
    fetchText()
  }, [])

  return (
    <>
      <div style={{color: 'black', margin: 0, padding: '0 10px'}}>
        { isLoading
          ? <div className='spn-container'>
              <span className='spinner'></span>
            </div>
          : <div style={{fontSize: '14px', lineHeight: 1.4}} dangerouslySetInnerHTML={text} />
        }
        <div 
          style={{
            display: 'flex', 
            flexDirection: innerWidth < 768 ? 'column' : 'row', 
            alignItems: 'center', 
            borderTop: '1px solid #00372A', 
            marginTop: 50, 
            marginBottom: 50}}
        >
          <label style={{ 
            display: 'flex',
            alignItems: 'flex-start', 
            marginLeft: innerWidth > 768 && -12, 
            marginBottom: 0, 
            marginTop: 50
          }}
        >
            <Checkbox
              onChange={handleChange}
              checked={form.termsAndConditions}
              style={{marginTop: -8}}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: '#00372A',
                },
              }}
            />
            <p style={{margin: 0, fontSize: '14px', lineHeight: 1.4, paddingRight: innerWidth > 768 && 20, cursor: 'pointer'}}>
              He entendido la finalidad del canal y sus términos y condiciones de uso y me comprometo a utilizarlo de forma responsable y ajustada a derecho, sabiendo que, en caso contrario, se podrán iniciar las medidas legales y/o disciplinarias que correspondan.
              {(isDirty && !form.termsAndConditions) && <p style={{color: 'red', fontWeight: 600}}>Debes aceptar las condiciones</p> }
            </p>
          </label>
          <ButtonsNavigation
            onClick={openModal}
            isDisabled={!form.termsAndConditions}
          />

        <div className="modal continueModal" onClick={() => {
            document.querySelector('div.continueModal').classList.remove('visible')
          }}
        >
          <div>
            <p>La comunicación deberá incluir la siguiente información: descripción de la posible conducta irregular, forma a través de la cual ha tenido conocimiento de los hechos, fecha y hora del suceso y datos de localización del hecho. En caso contrario, la comunicación será inadmitida.</p>
            <button
              style={buttonStyles}
              onClick={nextPage}
            >
              Aceptar y continuar
            </button>
          </div>
        </div>
        </div>
      </div>
    </>
  )
}

export default Step1
