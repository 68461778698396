import React, { useEffect, useState } from 'react'
import {Formik, FieldArray, ErrorMessage} from 'formik'
import ButtonsNavigation from '../commons/ButtonsNavigation'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {formValidation} from '../../validations/peopleAffected'
import InputField from '../commons/InputField'
import '../../styles/steps/Step6.css'

function Step6() {
  const [step, setStep, form, setForm, innerWidth] = useOutletContext()
  const navigate = useNavigate()

  const Form = ({ values, handleSubmit, isSubmitting, status }) => {
    const buttonStyles = (type) => {
      return {
        backgroundColor: type === 'add' ? '#c0c0c0' : 'red',
        borderRadius: 0,
        border: 0,
        color: 'white',
        cursor: 'pointer',
        fontWeight: 700,
        fontSize: 14,
        textTransform: 'uppercase',
        textAlign: 'center',
        padding: type === 'add' ? 10 : '10px 4px',
        maxWidth: type === 'add' ? '300px' : '100px'
      }
    }
  
    const addNewPerson = (arrayHelpers) => {
      arrayHelpers.push({
        name: '',
        lastName: '',
        job: '',
        relation: ''
      })
    }
  
    const formLabel = (key) => {
      let label = ''
  
      if (key === 'lastName') {
        label = 'Apellidos'
      } else if (key === 'job') {
        label = 'Cargo'
      } else if (key === 'relation') {
        label = 'Relación'
      } else {
        label = 'Nombre'
      }
  
      return label
    }

    const goBack = () => {
      if (form.complainant === null) {
        setForm(prevForm => ({
          ...prevForm,
          complainant: {
            name: '',
            lastName: '',
            email: '',
            job: '',
            phone: '',
            documentNumber: '',
            address: '',
            city: '',
            province: '',
            country: '',
            postalCode: ''
          }
        }))
      }

      navigate(`/paso/${step - 1}`)
    }
    
    return (
      <form onSubmit={handleSubmit}>
        <div style={{color: 'black', margin: 0, padding: '0 10px'}}>
          <h2 style={{marginTop: 30}}>Terceras personas afectadas:</h2>
          <div style={{display: 'flex', flexWrap: 'wrap', gap: 20, margin: 10,}}>
            <FieldArray
              name='personsAffected'
              render={arrayHelpers => (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  { values.personsAffected.length > 0
                      ? <div style={{display: 'flex', flexWrap: 'wrap', gap: 10, margin: 10}}>
                          { values.personsAffected.map((person, index) => {
                              const keys = Object.keys(person)

                              return (
                                <div 
                                  key={index} 
                                  style={{
                                    display: 'flex', 
                                    flexWrap: innerWidth > 768 ? 'wrap' : 'nowrap', 
                                    flexDirection: innerWidth < 768 ? 'column' : '', 
                                    gap: 10, 
                                    marginBottom: 20,
                                  }}
                                >
                                  {keys.map((key, i) => (
                                    <div key={i}>
                                      <InputField type='text' name={`personsAffected.${index}.${key}`} label={formLabel(key)} placeholder={formLabel(key)} />
                                      <ErrorMessage component='span' name={`personsAffected.${index}.${key}`} style={{display: 'none'}} />
                                    </div>
                                  ))}
                                  <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                    <span onClick={() => arrayHelpers.remove(index)}><svg width="24" height="24" fill='red' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0 1.104-.896 2-2 2zm-7-10.414l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm10-8.586h-20v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-8-3h-4v1h4v-1z"/></svg></span>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div>
                      : ''
                  }
                  <span style={buttonStyles('add')} onClick={() => addNewPerson(arrayHelpers)}>Añadir persona afectada</span>
                </div>
              )}
            />
          </div>
        </div>
        <ButtonsNavigation
          onBackClick={goBack}
          onClick={() => true}
        />
      </form>
    );
  }

  const onSubmit = (values, actions) => {
    setForm(prevForm => ({
      ...prevForm,
      personsAffected: values.personsAffected.length ? [...values.personsAffected] : []
    }))
    navigate(`/paso/${step + 1}`)
  }

  useEffect(() => {
    setStep(6)
    // if (!form.termsAndConditions || !form.termsOfUse) navigate('/')
  }, [])

  return (
    <Formik
      component={Form}
      initialValues={{personsAffected: form.personsAffected}}
      onSubmit={onSubmit}
      validationSchema={formValidation}
    >
    </Formik>
  )
}

export default Step6
