import React from 'react'
import {Button} from '@mui/material';

function ButtonsNavigation({ onBackClick, onClick, isForm, isLastStep, consentChecked, isDisabled }) {
  const styles = {
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  return (
    <div
      style={{
        display: 'flex',
        paddingTop: '40px',
        margin: '10px 0',
        justifyContent: 'space-between',
        gap: 30,
      }}
    >
      { (typeof onBackClick === 'function') && 
        <Button 
          type='button'
          style={{
            ...styles,
            backgroundColor: '#c0c0c0'
          }}
          variant='contained' 
          onClick={onBackClick}>Volver
        </Button> 
      }
      { (typeof onClick === 'function' && (!isForm && !isLastStep)) &&
        <Button
          type="submit"
          style={styles}
          variant="contained"
          disabled={true === isDisabled}
          onClick={() => {
            if (typeof onClick === 'function') {
              onClick()
            }
          }}
        >
          <span>Continuar</span>
        </Button>
      }
      { (typeof onClick !== 'undefined' && isForm) &&
        <Button
          type="submit"
          style={styles}
          variant="contained"
          disabled={true === isDisabled}
        >
          <span>Continuar</span>
        </Button>
      }
      { (typeof onClick !== 'undefined' && isLastStep) &&
        <Button
          type="button"
          style={styles}
          variant="contained"
          disabled={!consentChecked}
          onClick={onClick}
        >
          <span>Enviar</span>
        </Button>
      }
    </div>
  )
}

export default ButtonsNavigation
