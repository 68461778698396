import React, { useEffect, useState } from 'react';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import {Button} from '@mui/material';
import AdminIndex from './components/views/AdminIndex';
import Index from './components/Index';
import StepIndex from './components/views/StepIndex';
import Step1 from './components/steps/Step1';
import Step2 from './components/steps/Step2';
import Step3 from './components/steps/Step3';
import Step4 from './components/steps/Step4';
import Step5 from './components/steps/Step5';
import Step6 from './components/steps/Step6';
import Step7 from './components/steps/Step7';
import Step8 from './components/steps/Step8';
import ComplaintDetail from './components/ComplaintDetail';
import Login from './components/Login';
import './styles/App.css';
import AdminPortal from './components/AdminPortal';
import AdminComplaintDetail from './components/AdminComplaintDetail';

function App() {
  const [loginView, setLoginView] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    width: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  return (
    <div className="layout">
      <Routes>
        <Route path="/" element={<Index />}/>
        <Route path="/paso" element={<StepIndex/>}>
          <Route path="1" element={<Step1 />}/>
          <Route path="2" element={<Step2 />}/>
          <Route path="3" element={<Step3 />}/>
          <Route path="4" element={<Step4 />}/>
          <Route path="5" element={<Step5 />}/>
          <Route path="6" element={<Step6 />}/>
          <Route path="7" element={<Step7 />}/>
          <Route path="8" element={<Step8 />}/>
        </Route>
        <Route path="/comunicacion" element={<StepIndex/>}>
          <Route path=":reference" element={<ComplaintDetail />} />
        </Route>
        <Route path="/admin" element={<AdminIndex/>}>
          <Route path="login" element={<Login />} />
          <Route path="listado" element={<AdminPortal />} />
          <Route path="detalle/:id" element={<AdminComplaintDetail />} />
        </Route>
      </Routes>
    </div>
  )
}

export default App;
