import React, {useEffect, useState} from 'react'
import {Formik} from 'formik'
import InputField from '../commons/InputField'
import ButtonsNavigation from '../commons/ButtonsNavigation'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {formValidation} from '../../validations/description'

function Step4() {
  const [step, setStep, form, setForm] = useOutletContext()
  const navigate = useNavigate()
  
  let wordCounter = 0

  const wordCount = (e) => {
    const count = e.target.value;
    
    const countWords = (count) => {
      if (count.length === 0) {
        return 0;
      } else {
        count = count.replace(/(^\s*)|(\s*$)/gi,"");
        count = count.replace(/[ ]{2,}/gi," ");
        count = count.replace(/\n /,"\n");
        return count.split(' ').length; 
      }
    }

    wordCounter = countWords(count)
  }

  const Form = ({ handleSubmit, isSubmitting }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div style={{color: 'black', margin: 0, marginTop: 40, padding: '0 10px'}} onChange={wordCount}>
          <InputField type="text" multiline={true} name="text" label="Describa de forma detallada los hechos constituyentes de una posible conducta irregular" sx={{width: '100%'}} />
          {wordCounter > 130 && <p style={{color: '#d32f2f', fontSize: '0.75rem'}}>Máximo 130 palabras.</p>}
        </div>
        <ButtonsNavigation
          onBackClick={() => navigate('/paso/' + (step - 1))}
          onClick={() => true}
          isDisabled={wordCounter > 130}
          isForm={true}
        />
      </form>
    );
  }

  const onSubmit = (values, actions) => {
    setForm(prevForm => ({
      ...prevForm,
      ...values
    }))

    actions.setSubmitting(false)
    navigate(`/paso/${step + 1}`)
  }

  useEffect(() => {
    setStep(4)
    if (!form.termsAndConditions || !form.termsOfUse) navigate('/')
  }, [])

  return (
    <Formik
      component={Form}
      initialValues={form}
      onSubmit={onSubmit}
      validationSchema={formValidation}
    />
  )
}

export default Step4
