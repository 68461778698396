import * as yup from 'yup'

export const formValidation = yup.object().shape({
  username: yup
    .string()
    .required('Campo obligatorio')
    .trim(),
  password: yup
    .string()
    .required('Campo obligatorio')
    .trim()
})
