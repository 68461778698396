import React, {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";
import logo from '../../images/logo.png'
import {Outlet} from 'react-router-dom';
import '../../styles/steps/StepIndex.css'

function AdminIndex() {
  const navigate = useNavigate()

  const logout = () => {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    navigate('/admin/login')
  }

  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0,
    marginTop: '-20px',
  }

  return(
    <>
      <div style={{ width: '65%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{ display: 'flex', alignItems: 'center'}}>
          <img src={logo} style={{ width: '200px', paddingBottom: 30 }} />
          <h1 style={{ fontSize: '18px', marginTop: '-5px', marginLeft: '10px' }}>Portal de administración</h1>
        </div>
        <button style={buttonStyles} onClick={logout}>Cerrar sesión</button>
      </div>
      <div style={{ backgroundColor: 'white', padding: 30, width: '65%', margin: '0 0 100px 0'}}>
        <Outlet />
      </div>
    </>
  )
}

export default AdminIndex
