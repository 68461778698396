import React, {useEffect, useState} from 'react'
import {Formik} from 'formik'
import InputField from '../commons/InputField'
import ButtonsNavigation from '../commons/ButtonsNavigation'
import {useNavigate, useOutletContext} from 'react-router-dom'
import {formValidation} from '../../validations/personalData'

function Step5() {
  const [step, setStep, form, setForm] = useOutletContext()

  const navigate = useNavigate()

  const Form = ({ handleSubmit, isSubmitting, status }) => {
    const handleAnonymData = (e) => {
      setForm(prevForm => ({
        ...prevForm,
        anonymData: !form.anonymData
      }))
    }

    return (
      <form onSubmit={handleSubmit}>
        <div style={{color: 'black', margin: 0, padding: '0 10px'}}>
          <div style={{marginTop: 40, marginBottom: 0}}>
            <InputField type='checkbox' name='anonymData' label='Deseo continuar la comunicación de forma anónima' onChange={handleAnonymData} checked={form.anonymData} />
          </div>
          {!form.anonymData && 
            <>
              <p style={{ margin: '30px 0 30px 10px '}}>Introduzca sus datos personales:</p>
              <div style={{display: 'flex', flexWrap: 'wrap', gap: 20, margin: 10,}}>
                <InputField type="text" name="name" label="Nombre" placeholder="Nombre" />
                <InputField type="text" name="lastName" label="Apellidos" placeholder="Apellidos" />
                <InputField type="text" name="documentNumber" label="Documento de identidad" placeholder="Documento de identidad" />
                <InputField type="text" name="job" label="Cargo" placeholder="Trabajo" />
              </div>

              <p style={{ margin: '40px 0 30px 10px '}}>Puede proporcionar una dirección física o electrónica de notificaciones a efectos de mantener la comunicación con el responsable del canal de denuncias, de tal forma que éste pueda solicitar la información adicional oportuna en relación con la comunicación recibida, en su caso:</p>
              <div style={{display: 'flex', flexWrap: 'wrap', gap: 20, margin: 10,}}>
                <InputField type="text" name="email" label="Email" placeholder="Email" />
                <InputField type="text" name="phone" label="Teléfono" placeholder="Teléfono" />
                <InputField type="text" name="address" label="Domicilio" placeholder="Domicilio" />
                <InputField type="text" name="postalCode" label="Código postal" placeholder="Código postal" />
                <InputField type="text" name="city" label="Ciudad" placeholder="Ciudad" />
                <InputField type="text" name="province" label="Provincia" placeholder="Provincia" />
                <InputField type="text" name="country" label="País" placeholder="País" />
              </div>
            </>
          }
        </div>
        <ButtonsNavigation
          onBackClick={() => navigate('/paso/' + (step - 1))}
          onClick={() => {
            if (form.anonymData) {
              navigate(`/paso/${step + 1}`)
              setForm(prevForm => ({
                ...prevForm,
                complainant: null
              }))
            }
          }}
        />
      </form>
    );
  }

  const onSubmit = (values, actions) => {
    navigate(`/paso/${step + 1}`)

    const { name, lastName, email, job, phone, documentNumber, address, city, province, country, postalCode } = values
    setForm(prevForm => ({
      ...prevForm,
      complainant: {
        name,
        lastName,
        email,
        job,
        phone,
        documentNumber,
        address,
        city,
        province,
        country,
        postalCode
      }
    }))
  }

  useEffect(() => {
    setStep(5)
    if (!form.termsAndConditions || !form.termsOfUse) navigate('/')
  }, [])

  return (
    <Formik
      component={Form}
      initialValues={form.complainant}
      onSubmit={onSubmit}
      validationSchema={formValidation}
    >
    </Formik>
  )
}

export default Step5
