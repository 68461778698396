import React, { useEffect, useState } from "react"
import {useLocation, useNavigate} from "react-router-dom"
import { apiClient } from "../api/client"
import { transformedDate } from "../common/methods"
import '../styles/pages/ComplaintDetail.css'

function AdminComplaintDetail() {
  const location = useLocation()
  const navigate = useNavigate()

  const [info, setInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [newStatus, setNewStatus] = useState(null)
  const [statusList, setStatusList] = useState([])
  const [newComment, setNewComment] = useState('')

  useEffect(() => {
    if (location.state === null) {
      navigate('/admin/listado')
    }

    setIsLoading(true)

    apiClient.get(`/admin/complaint/${location?.state}`)
      .then(response => {
        if (response.hasOwnProperty('errors') && response.errors) {
          navigate('/admin/listado', {state: response})
        } else {
          setInfo(prevInfo => ({
            ...prevInfo,
            ...response,
            messages: response.messages.reverse(),
            statusHistory: response.statusHistory.reverse()
          }))
          setNewStatus(response.status?.id)
        }
        setIsLoading(false)
      })
    
      apiClient.get('/admin/complaint/choices')
        .then(status => setStatusList(status))
        .catch(() => setStatusList([]))
  }, [])

  const updateStatus = () => {
    setIsLoading(true)
    apiClient.post(`/admin/complaint/${location.state}/status/${newStatus}`, {})
      .then(response => {
        setInfo(prevInfo => ({
          ...prevInfo,
          status: response.status,
          statusHistory: response.statusHistory.reverse()
        }))
        setIsLoading(false)
      })
  }

  const updateComplaintComment = (e) => {
    setNewComment(e.target.value)
  }

  const sendComment = async () => {
    setIsLoading(true)
    try {
      const infoUpdated = await apiClient.post(`/admin/complaint/${location.state}/reply`, { text: newComment})
      setInfo(prevInfo => ({
        ...prevInfo,
        ...infoUpdated,
        messages: infoUpdated.messages.reverse(),
        statusHistory: infoUpdated.statusHistory.reverse()
      }))
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false)
    }
  }
  
  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  return (
    <div style={{color: 'black'}}>
      {
        isLoading
          ? <div className='spn-container'>
              <span className='spinner'></span>
            </div>
          : <>
                <div className="detail-container">
                  <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                    <div><strong>Descripción detallada:</strong> <br /> <blockquote style={{whiteSpace: 'pre-line'}}><i>{info.text}</i></blockquote></div>
                    <div><strong>Número de referencia: </strong>{info.reference}</div>
                    <div><strong>Estado de comunicación:</strong><span style={{display: 'block', width: '80%'}}>{info.status?.description}</span></div>
                    <div><strong>Fecha:</strong> {transformedDate(info.createdAt, 'date')}</div>
                    <div><strong>Hora:</strong> {transformedDate(info.createdAt, 'hour')}</div>

                    {info.complainant !== null &&
                      <>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                          <strong style={{marginBottom: -4}}>Datos del denunciante:</strong>
                          <span><span style={{fontWeight: 500}}>Nombre:</span> {info.complainant?.name}</span>
                          <span><span style={{fontWeight: 500}}>Apellidos:</span> {info.complainant?.lastName}</span>
                          <span><span style={{fontWeight: 500}}>Documento de identidad:</span> {info.complainant?.documentNumber}</span>
                          <span><span style={{fontWeight: 500}}>Cargo:</span> {info.complainant?.job}</span>
                          {info.complainant?.phone && <span><span style={{fontWeight: 500}}>Número de teléfono:</span> {info.complainant?.phone}</span>}
                          {info.complainant?.email && <span><span style={{fontWeight: 500}}>Correo electrónico:</span> {info.complainant?.email}</span>}
                          {info.complainant?.city && <span><span style={{fontWeight: 500}}>Ciudad:</span> {info.complainant?.city}</span>}
                        </div>
                      </>
                    }
            
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                      {info.personsAffected?.length > 0 && 
                        <>
                          <strong style={{marginBottom: -4}}>Personas afectadas:</strong>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: 30}}>
                              {info.personsAffected?.map((person, i) => (
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}} key={i}>
                                  <span><span style={{fontWeight: 500}}>Nombre:</span> {person.name}</span>
                                  <span><span style={{fontWeight: 500}}>Apellidos:</span> {person.lastName}</span>
                                  <span><span style={{fontWeight: 500}}>Cargo:</span> {person.job}</span>
                                  <span><span style={{fontWeight: 500}}>Relacion:</span> {person.relation}</span>
                                </div>
                              ))}
                            </div>
                        </>
                      }
                        
                      {info.attachments?.length > 0 && 
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                          <strong style={{marginBottom: -4}}>Documentos:</strong>
                          {info.attachments?.map((attachment, i) => (
                            <div style={{display: 'flex', flexDirection: 'column', gap: 6, marginBottom: 30}} key={i}>
                              <span>{attachment.description}</span>
                              <span><button onClick={() => window.open(attachment.url)} style={buttonStyles}>Abrir archivo</button></span>
                            </div>
                          ))}
                        </div>
                      }
                  </div>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                    <span style={{fontWeight: 600}}>Actualizar estado de la comunicación:</span>  
                    <select name="statusList" id="statusList" onChange={(e) => setNewStatus(e.target.value)} style={{padding: 4, maxWidth: 300}}>
                      <option value={info.status?.id}>{info.status?.shortDescription}</option>
                      {statusList?.filter(status => status.id !== info?.status.id).map(item => <option key={item.id} value={item.id}>{item.short_description}</option>)}
                    </select>
                    <button style={{...buttonStyles, marginTop: 10}} onClick={updateStatus}>Actualizar estado</button>
                  </div>
                </div>
                <p className="notes-title">Notas:</p>  
                <textarea defaultValue={info?.notes} onBlur={(e) => apiClient.post(`/admin/complaint/${location?.state}/notes`, { notes: e.target.value })}></textarea>
                <div className="comments-container">
                  <p className="notes-title comments-title">Respuesta al denunciante:</p>  
                  <textarea className="textarea-comments" onChange={updateComplaintComment}></textarea>
                  <button onClick={sendComment} style={{...buttonStyles, padding: '10px 30px', maxWidth: 210}}>Enviar respuesta</button>
                  {info.messages?.length > 0 &&
                    <>
                      <span className="comments-history-title" style={{fontWeight: 600}}>Respuestas al denunciante:</span>
                      <div className="comments-history">
                        {info.messages?.map((message, i) => {
                          const text = message.text.replace(/\n/g, '<br>')
                          return (
                            <div key={i} className="comments-history-list">
                              <div className="date-wrapper">
                                <strong>Fecha:</strong>
                                <p>{transformedDate(message.createdAt)}</p>
                              </div>
                              <div>
                                <p className="comments-history-text" dangerouslySetInnerHTML={{ __html: text }}></p>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </>
                  }
                </div>

                <div style={{marginTop: 30}}>
                  <span style={{fontWeight: 600}}>Historial de estados de la comunicación:</span>  
                  <div style={{display: 'flex', flexDirection: 'column', gap: 20, marginTop: 10}}>
                    {info.statusHistory?.map((status, i) => {
                      return (
                        <div key={i} className="status-history-wrapper">
                          <div style={{fontWeight: 600}}>{status.shortDescription}</div>
                          <div style={{marginTop: 6}}>{transformedDate(status.createdAt)}</div>  
                        </div>
                      )
                    })}
                  </div>
                </div>

                <button style={{...buttonStyles, marginTop: 30, padding: '10px 30px'}} onClick={() => navigate('/admin/listado')}>Volver</button>
            </>
      }
    </div>
  )
}

export default AdminComplaintDetail