import React from "react"
import { useNavigate } from "react-router-dom"
import { transformedDate } from '../common/methods'
import '../styles/components/ComplaintsList.css'

function ComplaintsListItem({ complaint }) {
  const navigate = useNavigate()

  return (
    <div className="complaints-list-wrapper">
      <div className="complaint column-ref">
        <p className="complaint-header" style={{fontWeight: 600}}>Número de referencia</p>
        <p>{complaint.reference}</p>
      </div>
      <div className="complaint column-status">
        <p className="complaint-header" style={{fontWeight: 600}}>Estado</p>
        <p className="status-label">{complaint.status.shortDescription}</p>
      </div>
      <div className="complaint column-date">
        <p className="complaint-header" style={{fontWeight: 600}}>Fecha</p>
        <p>{transformedDate(complaint.createdAt)}</p>
      </div>
      <button className="column-actions"
        style={{
          backgroundColor: '#00372A',
          border: 0,
          color: 'white',
          textTransform: 'uppercase',
          fontWeight: 600,
          cursor: 'pointer',
          padding: 10
        }}
        onClick={() => navigate(`/admin/detalle/${complaint.id}`, { state: complaint.id})}
      >
        Ver detalle
        </button>
    </div>
  )
}

export default ComplaintsListItem