import * as yup from 'yup'

export const formValidation = yup.object().shape({
  attachments: yup.array().of(
    yup.object().shape({
      description: yup
        .string()
        .required('Debes describir el contenido adjuntado'),
      fileName: yup
        .string()
        .required('Campo obligatorio'),
      fileData: yup
        .string()
        .required('Debes añadir un archivo'),
    })
  )
})