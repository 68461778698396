import React, { useState } from 'react';
import { useField } from 'formik';
import {TextField, TextareaAutosize, Checkbox, Select, MenuItem, InputLabel, FormControl} from '@mui/material'
import { checkboxClasses } from "@mui/material/Checkbox";

function InputField({ type, name, label, value, placeholder, areas, setValidated, onChange, ...props }) {
  const [field, meta] = useField(name)

  return (
    <>
      {(type === 'text' || type === 'password') &&
        <TextField 
          placeholder={placeholder}
          label={label}
          {...field}
          {...props}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error}
          inputProps={{
            // style: {
            //   width: 200,
            // }
          }}
          type={type}
        />
      }
      {type === 'textarea' &&
        <FormControl sx={{ width: '100%' }}>
          <TextareaAutosize
            placeholder={placeholder}
            {...field}
            {...props}
            style={{
              resize: 'none',
              width: '100%',
              minHeight: 50,
              fontFamily: '"Helvetica","Arial",sans-serif',
              fontSize: 16,
              padding: '4px',
              borderRadius: 4,
              borderColor: '#BEBEBE',
            }}
          />
          {(meta.touched && meta.error) ? <p style={{color: '#d32f2f', fontSize: '0.75rem', fontWeight: 500, margin: 0, marginTop: 10}}>{meta.error}</p> : ''}
        </FormControl>
      }
      {type === 'checkbox' &&
        <label style={{ cursor: 'pointer' }}><Checkbox
          {...field}
          {...props}
          onChange={onChange}
          sx={{
            [`&, &.${checkboxClasses.checked}`]: {
              color: '#00372A',
            },
          }}
          />
          {label}</label>
      }
      {type === 'select' &&
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>{label}</InputLabel>
          <Select
            {...field}
            {...props}
            autoWidth
            error={meta.touched && Boolean(meta.error)}
          >
            {areas?.map(area => <MenuItem key={area.id} value={area.id}>{area.name}</MenuItem> )}
          </Select>
          {(meta.touched && meta.error) ? <p style={{color: '#d32f2f', fontSize: '0.75rem', fontWeight: 500, margin: 0, marginTop: 10}}>{meta.error}</p> : ''}
        </FormControl>
      }
      {type === 'file' &&
        <TextField 
          name='upload-attachment'
          type='file'
          onChange={onChange}
        />
      }
      {type === 'date' &&
        <TextField 
          type='date'
          name={name}
          InputLabelProps={{shrink: true}}
          onChange={onChange}
        />
      }
    </>
  )
}

export default InputField
