import React, { useEffect, useState } from 'react'
import ButtonsNavigation from '../commons/ButtonsNavigation';
import {useNavigate, useOutletContext, useLocation} from 'react-router-dom';
import { apiClient } from '../../api/client';
import '../../styles/steps/StepIndex.css'

function Step2() {
  const [isLoading, setLoading] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const [complaintType, setComplaintType] = useState('new')
  const [referenceNumber, setReferenceNumber] = useState('')
  const [error, setError] = useState('')
  const [text, setText] = useState({__html: ''})

  const navigate = useNavigate()
  const location = useLocation()
  
  const [step, setStep, form, setForm, innerWidth] = useOutletContext()
  const modal = document.getElementById('modal-content')

  const fetchText = async () => {
    const response = await apiClient.get(process.env.REACT_APP_ENDPOINT_TEXT_STEP2)
    setText({__html: response})
    setLoading(false)
  }
  
  const handleModal = (e, type) => {
    e.preventDefault()
    document.querySelector('div.continueModal')?.classList.add('visible')
    setComplaintType(type)
  }

  const searchingComplaint = async() => {
    navigate(`/comunicacion/${referenceNumber}`, { state: referenceNumber })
  }

  useEffect(() => {
    if (referenceNumber.length === 0) {
      setError('')
    }
  }, [referenceNumber])

  useEffect(() => {
    setStep(2)
    fetchText()

    if (location.state !== null && (location.state.hasOwnProperty('errors') && location.state.errors)) {
      setError('No se ha encontrado ninguna comunicación con ese número de referencia, inténtelo de nuevo.')
      document.querySelector('div.continueModal')?.classList.add('visible')
    }
  }, [])

  const buttonStyles = {
    padding: '10px 20px',
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    borderRadius: 0,
    lineHeight: 1.6
  }

  return (
    <>
    
      <div style={{color: 'black', margin: 0, marginBottom: 20, padding: '0 10px'}}>
        <h2>Canal de denuncias</h2>

        <div 
          style={{
            display: 'flex', 
            flexDirection: innerWidth < 768 ? 'column' : 'row', 
            justifyContent: 'space-between', 
            gap: 20,
            marginTop: 100, 
            marginBottom: 100, 
            marginLeft: 50, 
            marginRight: 50
          }}
        >
          <button
            onClick={(e) => handleModal(e, 'previous')}
            style={buttonStyles}
          >
            <strong style={{ display: 'block', fontSize: 16 }}>Consultar</strong>comunicación previa
          </button>
          <button
            style={buttonStyles}
            onClick={() => navigate('/paso/3')}
          >
            <strong style={{ display: 'block', fontSize: 16 }}>Enviar</strong>nueva comunicación
          </button>
        </div>
        {
          isLoading
            ? <div className='spn-container'>
                <span className='spinner'></span>
              </div>
            : <div className="faq-text" dangerouslySetInnerHTML={text} />
        }
      </div>
      <div className="modal continueModal" onClick={() => {
        if (openModal) {
          setOpenModal(false)
          return
        }

        setOpenModal(false)
        setError('')
        document.querySelector('div.continueModal').classList.remove('visible')
      }}>
        <div style={{color: 'black'}}>
          <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
            <span>Introduce el número de referencia de tu comunicación:</span>
            <input 
              type="text" 
              style={{
                padding: 16, 
                borderRadius: 4, 
                border: 0, 
                padding: '16.5px 14px', 
                borderStyle: 'solid', 
                borderWidth: 1, 
                borderColor: '#0000003b'
              }} 
              onChange={(e) => setReferenceNumber(e.target.value)}
              onFocus={() => {
                setError('')
                setOpenModal(true)
              }} 
            />
            {error.length > 0 && <span style={{textAlign: 'center', color: 'red'}}>{error}</span>}
            <div style={{display:'flex', justifyContent: 'center'}}>
              <button 
                style={{...buttonStyles, opacity: referenceNumber.length === 0 && .6}}
                onClick={searchingComplaint}
                disabled={referenceNumber.length === 0}
              >
                Ver comunicación
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Step2
