import React from 'react'
import {Navigate} from 'react-router-dom';

function Index() {
  return (
    <Navigate to="/paso/1"></Navigate>
  )
}

export default Index
