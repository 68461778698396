import React, { useEffect, useState } from "react"
import {useLocation, useNavigate} from "react-router-dom"
import { apiClient } from "../api/client"
import { transformedDate } from "../common/methods"

function ComplaintDetail() {
  const location = useLocation()
  const navigate = useNavigate()

  const [info, setInfo] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (location.state === null) {
      navigate('/paso/2')
    }

    setIsLoading(true)

    apiClient.get(`/complaint/${location?.state}`)
      .then(response => {
        if (response.hasOwnProperty('errors') && response.errors) {
          navigate('/paso/2', {state: response})
        } else {
          setInfo(response)
        }
        setIsLoading(false)
      })
  }, [])
  
  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  return (
    <div style={{color: 'black'}}>
      {
        isLoading
          ? <div className='spn-container'>
              <span className='spinner'></span>
            </div>
          : <>
                <div style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                    <div><strong>Descripción detallada:</strong> <br /> <blockquote style={{whiteSpace: 'pre-line'}}><i>{info.text}</i></blockquote></div>
                    <div><strong>Número de referencia:</strong> {info.reference}</div>
                    <div><strong>Estado de comunicación:</strong><span> {info.status?.description}</span></div>
                    <div><strong>Fecha:</strong> {transformedDate(info.createdAt, 'date')}</div>
                    <div><strong>Hora:</strong> {transformedDate(info.createdAt, 'hour')}</div>

                    {info.complainant !== null &&
                      <>
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                          <strong style={{marginBottom: -4}}>Datos del denunciante:</strong>
                          <span><span style={{fontWeight: 500}}>Nombre:</span> {info.complainant?.name}</span>
                          <span><span style={{fontWeight: 500}}>Apellidos:</span> {info.complainant?.lastName}</span>
                          <span><span style={{fontWeight: 500}}>Documento de identidad:</span> {info.complainant?.documentNumber}</span>
                          <span><span style={{fontWeight: 500}}>Cargo:</span> {info.complainant?.job}</span>
                          {info.complainant?.phone && <span><span style={{fontWeight: 500}}>Número de teléfono:</span> {info.complainant?.phone}</span>}
                          {info.complainant?.email && <span><span style={{fontWeight: 500}}>Correo electrónico:</span> {info.complainant?.email}</span>}
                          {info.complainant?.city && <span><span style={{fontWeight: 500}}>Ciudad:</span> {info.complainant?.city}</span>}
                        </div>
                      </>
                    }
          
                    <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                      {info.personsAffected?.length > 0 && 
                        <>
                          <strong style={{marginBottom: -4}}>Personas afectadas:</strong>
                            <div style={{display: 'flex', flexWrap: 'wrap', gap: 30}}>
                              {info.personsAffected?.map((person, i) => (
                                <div style={{display: 'flex', flexDirection: 'column', gap: 6}} key={i}>
                                  <span><span style={{fontWeight: 500}}>Nombre:</span> {person.name}</span>
                                  <span><span style={{fontWeight: 500}}>Apellidos:</span> {person.lastName}</span>
                                  <span><span style={{fontWeight: 500}}>Cargo:</span> {person.job}</span>
                                  <span><span style={{fontWeight: 500}}>Relacion:</span> {person.relation}</span>
                                </div>
                              ))}
                            </div>
                        </>
                      }
                      
                      {info.attachments?.length > 0 && 
                        <div style={{display: 'flex', flexDirection: 'column', gap: 10}}>
                          <strong style={{marginBottom: -4}}>Documentos:</strong>
                          {info.attachments?.map((attachment, i) => (
                            <div style={{display: 'flex', flexDirection: 'column', gap: 6, marginBottom: 30}} key={i}>
                              <span>{attachment.description}</span>
                              <span><button onClick={() => window.open(attachment.url)} style={buttonStyles}>Abrir archivo</button></span>
                            </div>
                          ))}
                        </div>
                      }
                    </div>
          
                    <button style={buttonStyles} onClick={() => navigate('/paso/2')}>Volver</button>
                </div>
            </>
      }
    </div>
  )
}

export default ComplaintDetail