import React, {useEffect, useState} from 'react'
import {Formik, FieldArray, ErrorMessage} from 'formik'
import InputField from '../commons/InputField'
import ButtonsNavigation from '../commons/ButtonsNavigation'
import {useNavigate, useOutletContext} from 'react-router-dom'
import { formValidation } from '../../validations/attachments'

function Step7() {
  const [step, setStep, form, setForm] = useOutletContext()

  const navigate = useNavigate()

  const buttonStyles = (type) => {
    return {
      backgroundColor: type === 'add' ? '#c0c0c0' : 'red',
      borderRadius: 0,
      border: 0,
      color: 'white',
      cursor: 'pointer',
      fontWeight: 700,
      fontSize: 14,
      textTransform: 'uppercase',
      textAlign: 'center',
      padding: type === 'add' ? 10 : '10px 4px',
      maxWidth: type === 'add' ? '300px' : '100px'
    }
  }

  const formLabel = (key) => {
    let label = ''

    if (key === 'description') {
      label = 'Descripción del contenido'
    } else if (key === 'fileName') {
      label = 'Nombre de archivo'
    } else if (key === 'fileData') {
      label = 'Adjuntar archivo'
    } else {
      label = ''
    }

    return label
  }

  const addNewAttachment = (arrayHelpers) => {
    arrayHelpers.push({
      description: '',
      fileName: '',
      fileData: '',
      fileType: ''
    })
  }

  const handleAttachment = (e, attachment) => {
    const file = e.target.files[0]
    const reader = new FileReader()
    reader.onloadend = () => {
      attachment.fileData = reader.result.replace('data:', '').replace(/^.+,/, '')
      attachment.fileName = file.name
      attachment.fileType = file.type
    }
    reader.readAsDataURL(file)
  }

  const Form = ({ values, handleSubmit, isSubmitting, status }) => {
    return (
      <form onSubmit={handleSubmit}>
        <div style={{color: 'black', margin: 0, padding: '0 10px'}}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'flex-start', margin: '10px 0'}}>
            <h2 style={{marginTop: 30}}>Documentos adicionales</h2>
            <p style={{marginBottom: 20}}>Adjunte cualquier documento que considere relevante en relación al objeto de la denuncia:</p>
            <div style={{display: 'flex', flexDirection: 'column', gap: 20, margin: 10,}}>
            <FieldArray 
              name='attachments'
              render={arrayHelpers => (
                <> 
                  { values.attachments.length 
                      ? <div style={{display: 'flex', flexWrap: 'wrap', alignItems: 'baseline', gap: 30, margin: 10}}>
                          { values.attachments.map((attachment, index) => {
                              return (
                                <div key={index} style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                                  <InputField type='text' name={`attachments.${index}.description`} label={formLabel('description')} placeholder={formLabel('description')} />
                                  <ErrorMessage component='span' name={`attachments.${index}.description`} style={{color: 'red', display: 'none'}} />

                                  <div style={{display: 'flex', gap: 20}}>
                                    <div style={{display: 'flex', flexDirection: 'column'}}>
                                      <InputField type='file' name={`attachments.${index}.fileData`} onChange={e => handleAttachment(e, attachment)} />
                                      <ErrorMessage component='span' name={`attachments.${index}.fileData`} style={{color: 'red'}} />
                                    </div>
                                    <div style={{display: 'flex', cursor: 'pointer', marginTop: 16}}>
                                      <span onClick={() => arrayHelpers.remove(index)}><svg width="24" height="24" fill='red' xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd"><path d="M19 24h-14c-1.104 0-2-.896-2-2v-16h18v16c0 1.104-.896 2-2 2zm-7-10.414l3.293-3.293 1.414 1.414-3.293 3.293 3.293 3.293-1.414 1.414-3.293-3.293-3.293 3.293-1.414-1.414 3.293-3.293-3.293-3.293 1.414-1.414 3.293 3.293zm10-8.586h-20v-2h6v-1.5c0-.827.673-1.5 1.5-1.5h5c.825 0 1.5.671 1.5 1.5v1.5h6v2zm-8-3h-4v1h4v-1z"/></svg></span>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                          }
                        </div> 
                      : ''
                  }
                  <span style={buttonStyles('add')} onClick={() => addNewAttachment(arrayHelpers)}>Añadir documento</span>
                </>
              )}
            />
          </div>
          </div>
          
        </div>
        <ButtonsNavigation
          onBackClick={() => navigate('/paso/' + (step - 1))}
          onClick={() => true}
        />
      </form>
    );
  }

  const onSubmit = (values, actions) => {
    setForm(prevForm => ({
      ...prevForm,
      attachments: values.attachments.length ? [...values.attachments] : []
    }))
    navigate(`/paso/${step + 1}`)
  }

  useEffect(() => {
    setStep(7)
    if (!form.termsAndConditions || !form.termsOfUse) navigate('/')
  }, [])

  return (
    <Formik
      component={Form}
      initialValues={{attachments: form.attachments}}
      onSubmit={onSubmit}
      validationSchema={formValidation}
    >
    </Formik>
  )
}

export default Step7
