const transformedDate = (date, type) => {
  let newDate = null

  if (type === 'toApi') {
    newDate = new Date(date)
  } else {
    newDate = new Date(date * 1000)
  }

  const day = newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate()
  const month = newDate.getMonth() < 9 ? `0${newDate.getMonth() + 1}` : newDate.getMonth() + 1
  const year = newDate.getFullYear()

  const hour = newDate.getHours()
  const minutes = newDate.getMinutes()

  if (type === 'hour') {
    return `${hour}:${minutes < 10 ? `0${minutes}` : minutes}`
  }

  if (type === 'toApi') {
    return `${year}-${month}-${day}`
  }

  return `${day}/${month}/${year}`
}

export {
  transformedDate
}