import React, { useEffect, useState } from 'react'
import {apiClient} from '../../api/client'
import {Checkbox} from '@mui/material'
import {useNavigate, useOutletContext} from 'react-router-dom';
import ButtonsNavigation from '../commons/ButtonsNavigation';
import { checkboxClasses } from "@mui/material/Checkbox";
import '../../styles/steps/StepIndex.css'

function Step3() {
  const [step, setStep, form, setForm] = useOutletContext()
  const navigate = useNavigate()

  const nextPage = () => {
    if (form.termsOfUse) {
      navigate('/paso/' + (step + 1))
    }
  }

  const handleCheckbox = (e, key) => {
    console.log('click--')
    setForm(prevForm => ({
      ...prevForm,
      [key]: key === 'termsOfUse' ? e.target.checked : true
    }))
  }

  const buttonStyles = {
    padding: 10,
    color: 'white',
    textTransform: 'uppercase',
    border: 0,
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: 14,
    maxWidth: '300px',
    backgroundColor: '#00372A',
    fontWeight: 'bold',
    borderRadius: 0
  }

  useEffect(() => {
    setStep(3)
    if (!form.termsAndConditions) navigate('/')
  }, [])

  return (
    <>
      <div style={{color: 'black', margin: 0, padding: '0 10px'}}>
        <div 
          style={{
            display: 'flex', 
            flexDirection: 'column',
            alignItems: 'flex-start', 
            marginBottom: 0
          }}
        >
          <a href="https://www.manolobakes.com/politica-de-privacidad/" target="_blank" type="application/pdf" style={{marginTop: 30}} onClick={(e) => handleCheckbox(e, 'openedTermsFile')} >
            <button 
              style={buttonStyles}
            >
              Leer condiciones de uso
            </button>
          </a>
          <label style={{ display: 'flex', alignItems: 'flex-start', marginLeft: -12, marginBottom: 0, marginTop: 30}}>
            <Checkbox
              onChange={(e) => handleCheckbox(e, 'termsOfUse')}
              checked={form.termsOfUse}
              style={{marginTop: -11}}
              sx={{
                [`&, &.${checkboxClasses.checked}`]: {
                  color: '#00372A',
                },
              }}
              disabled={!form.openedTermsFile}
            />
            
            <span style={{margin: 0, fontSize: '14px', lineHeight: 1.4, cursor: 'pointer'}}>
              He leído y comprendido los términos y condiciones de uso.
            </span>
          </label>
          {(!form.openedTermsFile && !form.termsOfUse) && <p style={{fontWeight: 600, margin: 0}}>Debes visualizar las condiciones antes de continuar</p>}
          {(form.openedTermsFile && !form.termsOfUse) && <p style={{color: 'red', fontWeight: 600, margin: 0}}>Debes aceptar las condiciones</p>}
        </div>
        <ButtonsNavigation
          onBackClick={() => navigate('/paso/' + (step - 1))}
          onClick={nextPage}
          isDisabled={!form.termsOfUse}
        />
      </div>
    </>
  )
}

export default Step3
