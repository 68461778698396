const API_BASE_URI = process.env.REACT_APP_API_BASE_URI

export const APP_VERSION = '0.1'

export const ACCESS_TOKEN_KEY = 'accessToken'
export const REFRESH_TOKEN_KEY = 'refreshToken'
export const USER_KEY = 'username'

export const ERRORS = {
  SERVER: 'server',
  UNAUTHORIZED: 'unauthorized',
  VERSION_MINIMUM: 'version_minimum'
}

export const apiClient = {
  get(endpoint) {
    return request(endpoint, 'GET', {})
  },
  post(endpoint, payload) {
    return request(endpoint, 'POST', payload)
  }
}

async function request(endpoint, method, payload) {
  let options = {
    method,
    headers: {
      'Content-Type': 'application/json',
      'App-Version': APP_VERSION
    }
  }

  const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY)
  if (accessToken) {
    options.headers.Authorization = `Bearer ${accessToken}`
  }

  if (Object.keys(payload).length > 0) {
    options.body = JSON.stringify(payload)
  }

  const response = await fetch(`${API_BASE_URI}${endpoint}`, options)
  const status = response.status.toString()

  if ('410' === status) {
    await refreshToken(options)
    return request(endpoint, method, payload)
  }

  return await checkErrorsAndGetJson(status, response)
}

async function refreshToken(options) {
  delete options.headers.Authorization
  options.method = 'POST'
  const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY)
  const username = localStorage.getItem(USER_KEY)
  options.body = JSON.stringify({refreshToken, username})

  const response = await fetch(`${API_BASE_URI}/admin/refresh-token`, options)
  const status = response.status.toString()
  
  const json = await checkErrorsAndGetJson(status, response)
  localStorage.setItem(ACCESS_TOKEN_KEY, json.accessToken)
  localStorage.setItem(REFRESH_TOKEN_KEY, json.refreshToken)
}

async function checkErrorsAndGetJson(status, response) {
  const statusGroup = status.charAt(0);
  if ('5' === statusGroup) {
    throw new Error(ERRORS.SERVER);
  }

  if ('401' === status || '403' === status) {
    localStorage.removeItem(ACCESS_TOKEN_KEY)
    localStorage.removeItem(REFRESH_TOKEN_KEY)
    throw new Error(ERRORS.UNAUTHORIZED);
  }

  if ('409' === status) {
    throw new Error(ERRORS.VERSION_MINIMUM);
  }

  const json = await response.json();
  if ('400' === status) {
    throw json;
  }

  return json;
}
