import React, {useEffect, useState} from 'react'
import logo from '../../images/logo.png'
import {Step, StepLabel, Stepper} from '@mui/material';
import {Outlet} from 'react-router-dom';
import '../../styles/steps/StepIndex.css'

function StepIndex() {
  const [step, setStep] = useState(1)
  const [innerWidth, setInnerWidth] = useState(window.innerWidth)

  const stepsOption = [
    {
      name: 'Descripción de la comunicación',
      step: 4
    },
    {
      name: 'Datos de contacto',
      step: 5
    },
    {
      name: 'Personas afectadas',
      step: 6
    },
    {
      name: 'Documentos adjuntos',
      step: 7
    },
    {
      name: 'Envío',
      step: 8
    },
  ]

  const [form, setForm] = useState({
    termsAndConditions: false,
    openedTermsFile: false,
    termsOfUse: false,
    anonymData: false,
    text: '',
    complainant: {
      name: '',
      lastName: '',
      job: '',
      phone: '',
      documentNumber: '',
      address: '',
      city: '',
      province: '',
      country: '',
      postalCode: ''
    },
    personsAffected: [],
    attachments: [],
    dataConsentCheck: false
  })

  useEffect(() => {
    const updateWidth = () => {
      setInnerWidth(window.innerWidth)
    }

    window.addEventListener('resize', updateWidth)
    updateWidth()

    return () => window.removeEventListener('resize', updateWidth)
  }, [window.innerWidth])
  

  return(
    <>
      <div style={{ width: '65%'}}>
        <img src={logo} style={{ width: '200px', paddingBottom: 30 }} />
      </div>
      <div style={{ backgroundColor: 'white', padding: 30, width: '65%', margin: '0 0 100px 0'}}>
        {step >= 4 &&
        <Stepper activeStep={(step-4)}>
          {stepsOption.map((option, i) => {
            return (
              <Step 
                key={i} 
                sx={{
                  '& .MuiStepLabel-root .Mui-completed': {
                    color: '#00372A',
                  },
                  '& .MuiStepLabel-root .Mui-active': {
                    color: '#00372A',
                  },
                }}
                style={{display: innerWidth < 768 ? option.step === step ? 'block' : 'none' : 'block'}}
              >
                <StepLabel>{option.name}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
        }
        <Outlet context={[step, setStep, form, setForm, innerWidth]} />
      </div>
    </>
  )
}

export default StepIndex
