import * as yup from 'yup'

export const formValidation = yup.object().shape({
  name: yup
    .string()
    .required('Debes escribir tu nombre')
    .trim()
    .min(3, 'Mínimo 3 caracteres'),
  lastName: yup
    .string()
    .required('Debes escribir tu apellido')
    .trim()
    .min(3, 'Mínimo 3 caracteres'),
  email: yup
    .string()
    .trim()
    .email('Debes escribir un email válido'),
  job: yup
    .string(),
  phone: yup
    .string(),
  documentNumber:yup
    .string()
    .required('Campo obligario'),
  address: yup
    .string(),
  city: yup
    .string(),
  province: yup
    .string(),
  country: yup
    .string(),
  postalCode: yup
    .string(),
})
